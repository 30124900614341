import { Layout } from '@components/Layout';
import { Result } from 'antd';
import Link from 'next/link';
import React from 'react';

const NotFoundPage = () => (
  <Layout>
    <Result
      status="404"
      title="Oops... Page not found"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Link href="/">Take Me Home</Link>}
      className="hScreen"
    />
  </Layout>
);

export default NotFoundPage;
